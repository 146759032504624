import React from "react"
import Layout from "@components/layout";

import 'tw-elements';
import icon_t_SVG1 from '@images/top_t_icon1.svg';

import { SeoContents } from "@components/seo-data.js" // Seo情報設定

export default function PropThanks(){
  
  // Seo情報設定
  const pathName = 'thanks';
  const seoTag = SeoContents({ Path:pathName });

  return(
  <>
    <Layout>
      {seoTag}
      <div className="text-center mb-8 mb-10 mt-10"><img className="mb-5 mx-auto" alt="" src={icon_t_SVG1}/>
        <h2 className="font-semibold tracking-wide text-lg md:text-[18px] text-[#36A7C3]">お問い合わせいただいた内容は<br/>無事に送信されました</h2>
      </div>
      <div className="max-w-xl mx-auto py-11 md:max-w-5xl px-6 mb-10 text-center">
        <p>
          この度はパートナーに申請いただき誠にありがとうございます。<br/>
          申請時に登録いただいたメールアドレスに確認メールが送信されます。<br/>
          ご確認ください。
        </p>
      </div>
    </Layout>
  </>
 )
}